body {
  margin: 0;
  font-family: "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Poppins";
  src: local("PoppinsRegular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}
.vocalysed-home{
    background-color: #f8f9fd;
    padding: 12px 0 0 12px;
    min-height: calc(100vh - 103px)!important;
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000!important;
    border-width: 1px!important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline legend{
    color: #000000de!important;
}
.MuiInputLabel-outlined{
    color: #00000099!important;
}
.MuiOutlinedInput-notchedOutline legend, .Mui-focused.MuiInputLabel-outlined{
    color: #000000de!important;
}
[contenteditable="true"]:focus {
    outline: none!important;
    outline-width: 0!important;
    border-color: #767676;
}
header{
    border-bottom: 1px solid #DEE2F1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}
.canvasjs-chart-credit{
    display:none!important;
}
header.Campaign, header.Campaigns, header.Conversation, header.Playlists, header.Admin, header.Tracker.admin, header.Organisation.setup{
    border-bottom: none;
}
.bold{
    font-weight: bold;
}
.vocalysd-card{
    background: #F8F9FD;
    box-shadow: 9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF;
    border-radius: 20px;
    margin: 0px 24px 24px 0px;
    padding: 12px 16px 19px;
    min-width: 200px;
    /*overflow: auto;*/
}
.vocalysd-card.card-multiple{
    padding: 0px;
}
.vocalysd-card.card-multiple .card-piece{
    padding: 24px;
}
.vocalysd-card.vocalysd-table{
    padding: 3px 0px 0px 0px;
}
.vocalysd-card.vocalysd-table table{
    border-radius: 20px;
}
.vocalysd-card.vocalysd-table table tbody tr:last-child td{
    border-bottom: 0px;
}
.vocalysd-card.vocalysd-table table tbody tr:hover{
    background: #FFFFFF;
    box-shadow: inset 4px 4px 5px #D1D9E6;
}
.vocalysd-card.vocalysd-table table tbody tr.selected-mui{
    background: #FFFFFF;
    box-shadow: inset 4px 4px 5px #D1D9E6;
}
.vocalysd-card.vocalysd-table table tbody tr:hover td{
}
.vocalysd-card.vocalysd-table table tbody tr:hover:last-child{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.vocalysd-card.vocalysd-table table tbody tr:hover:last-child td:first-child{
    border-bottom-left-radius: 20px;
}
.vocalysd-card.vocalysd-table table tbody tr:hover:last-child td:last-child{
    border-bottom-right-radius: 20px;
}
.vocalysd-card.vocalysd-table.table-no-last table tbody tr:hover:last-child{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.vocalysd-card.vocalysd-table.table-no-last table tbody tr:hover:last-child td:first-child{
    border-bottom-left-radius: 0px;
}
.vocalysd-card.vocalysd-table.table-no-last table tbody tr:hover:last-child td:last-child{
    border-bottom-right-radius: 0px;
}
.vocalysd-card .vocalysd-heading{
    font-weight: 600;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
}
.MuiLinearProgress-bar{
    background: linear-gradient(270deg, #9F40C1 0%, #c976e7 100%);
}

.bar-positive .MuiLinearProgress-bar{
    background: #21A500;
    background-color: #21A500!important;
    background: linear-gradient(270deg, #1a8300 0%, #21A500 100%);
}
.bar-negative .MuiLinearProgress-bar{
    background: #E7A500;
    background-color: #E7A500!important;
    background: linear-gradient(270deg, #E87522 0%, #E7A500 100%);
}
.bar-neutral .MuiLinearProgress-bar{
    background: #a1a5b4;
    background-color: #a1a5b4!important;
    background: linear-gradient(270deg, #999DAC 0%, #DEE2F1 100%);
}
.MuiBox-root{
    color: #FFFFFF!important;
}
.MuiLinearProgress-root{
    background: #FFFFFF;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 24px!important;
    height: 12px!important;
}
.analytics .vocalysd-card .MuiLinearProgress-root:after{
    content: "";
    height: 12px;
    width: 2px;
    color: #999DAC;
    background-color: #999DAC;
    right: 10%;
    position: absolute;
}
.header-icon{
    width: 40px;
    margin-right: 20px;
    height: 40px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(209 217 230) 2px 2px 3px, rgb(255 255 255) 2px 2px 3px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    display: inline-block;
    text-align: center;
}
.header-icon svg{
    padding-top: 8px;
    color: rgb(222, 226, 241);
}
.MuiIconButton-sizeMedium{
    padding: 0px!important;
    margin-left: 8px!important; 
}
.MuiChip-filled{
    height: 24px!important;
}
.MuiButtonGroup-root{
    /*border: 1px solid #DEE2F1;*/
    box-shadow: none!important;
}
.MuiButton-containedPrimary{
    text-transform: none;
    background: #E7A500;
    color: rgb(255, 255, 255);
    font-family: Poppins;
    border-right: 0!important;
}
.MuiSwitch-switchBase.Mui-checked{
    color: #E7A500!important;
}
.Mui-checked+.MuiSwitch-track{
    background-color: #E7A500!important;
}
.MuiPaper-root.Mui-expanded{
    margin: 0px!important;
}
.MuiButtonBase-root.Mui-expanded{
    min-height: 48px!important;
}
.MuiButtonBase-root.Mui-expanded .MuiAccordionSummary-content{
    margin: 12px 0!important;
}
.MuiCheckbox-root.Mui-checked svg{
    color: #21A500!important;
}
.buttons-border-extend{
    border-radius: 24px;
    box-shadow:none;
}
.buttons-border-extend button{
    border-radius: 24px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.buttons-border-extend button:first-child{
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.buttons-border-extend button:last-child{
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.feather-button-hover:hover{
    box-shadow: rgb(209 217 230) 4px 4px 5px inset, rgb(255 255 255) -4px -4px 5px inset!important;
}
.feather-icon-bordered{
    width: 40px;
    min-width: 40px;
    margin-right: 0;
    margin-left: 5px;
    height: 40px;
    background: #fff;
    box-shadow: 2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF;
    border-radius: 22px;
    justify-content: center;
    color: #E7A500;
    cursor: pointer;
    align-items: center;
    display: flex;
    border: 2px solid rgb(231, 165, 0);
}
.feather-icon-bordered:hover{
    box-shadow: rgb(209 217 230) 4px 4px 5px inset, rgb(255 255 255) -4px -4px 5px inset!important;
}
.button-light{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: none;
    color: #e7a500;
    border: 1px solid #ddd;
    font-family: "Poppins";
    font-size: 14px;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 25px;
}
.button-light:hover{
    box-shadow: rgb(209 217 230) 4px 4px 5px inset, rgb(255 255 255) -4px -4px 5px inset!important;
    background:#FFF;
}
.piechart .recharts-legend-item{
    display: flex!important;
    margin-right: 0px!important;
    align-items: center;
}
.piechart .recharts-default-legend .recharts-surface{
    display: flex;
    height: 18px;
    width: 18px;
}
.piechart .recharts-legend-item-text{
    display: flex;
    line-height: 20px;
}
.linechart .recharts-legend-wrapper{
    bottom: 0px!important;
}

.menu-item-selected {
    margin-left: 5px;
    border-bottom: solid 2px;
    font-weight: bolder;
    margin-bottom: 5px;
    text-align:center;
    cursor: pointer;
}

.tab-selected {
    margin-left: 5px;
    border-bottom: solid 2px;
    font-weight: bolder;
    margin-bottom: 5px;
    cursor: pointer;
}

.snack-bar {
    position: absolute !important;
    top: 10px !important;
    left: 40% !important;
    max-width: 400px !important;
    max-block-size: max-content !important;
    animation: ease-in-out;
}

.snippets-shading {
    -webkit-border-radius: 4px;
    -moz-border-radius: 3px;
    border-radius: 4px;
    padding-top: 10px;
    padding-right:10px;
    padding-bottom: 5px;
    margin-left: 20px;
    margin-bottom: 20px;
    background-color: rgba(170,170,170,0.3);
}

.tab-item {
    padding-top: 10px;
}

.new-campaign-upload-page-message {
    font-family: Poppins;
    text-align: center;
    font-size: 24px;
    color: dimgray;
    padding: 200px 250px 250px 250px;
    font-style: italic;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* Read more summary */
.labellesstooltip .recharts-tooltip-label{
    display: none;
}
.read-more-state {
  display: none;
}
.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}
.read-more-target2 {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
  transition: .25s ease;
}
.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}
.read-more-state:checked ~ .read-more-wrap .read-more-target2 {
  opacity: 0;
  max-height: 0;
  font-size: 0;
}
.read-more-state ~ .read-more-trigger:before {
  content: 'Show more';
}
.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Show less';
}
.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em;
}

.search-bar-alignment {
    margin-left: auto;
    margin-right: 10px;
    padding-bottom: 20px;
}

.searchbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchbarClear {
    margin-left: 10px;
    cursor: pointer;
}

.searchbarInput {
    height: 30px;
    width: 300px;
    font-size: 16px;
}